export default {
  path: '/:storeId/criteo/',
  component: () => import('@/app/criteo/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/criteo/views/Home.vue'),
    },
    {
      path: 'user',
      component: () => import('@/app/criteo/views/User.vue'),
    },
    {
      path: 'feed',
      component: () => import('@/app/criteo/views/Feed.vue'),
    },
    {
      path: 'feed-edit/:feedId?',
      component: () => import('@/app/criteo/views/FeedEdit.vue'),
    },
    {
      path: 'lexicon',
      component: () => import('@/app/criteo/views/Lexicon.vue'),
    },
    {
      path: 'audiences',
      component: () => import('@/app/criteo/views/Audiences.vue'),
    },
  ],
};
