export default {
  path: '/:storeId/detailinquiry/',
  component: () => import('@/app/detailinquiry/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/detailinquiry/views/Home.vue'),
    },
  ],
};
