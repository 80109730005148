<template>
  <el-date-picker
    v-model="pikerValue"
    :type="timeType"
    size="small"
    :editable="false"
    range-separator="至"
    :format="timeValue"
    @change="timeChange"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :placeholder="placeholder"
  >
  </el-date-picker>
</template>

<script>
import { ref } from 'vue';
import { parseTime } from '@/tools/index';
export default {
  name: 'datepicker',
  emits: ['timeChange'], // this line
  props: {
    timeType: {
      type: String,
      default: 'date', //date,datetime,daterange
    },
    placeholder: {
      type: String,
      default: '请选择日期',
    },
    startPlaceholder: {
      type: String,
      default: '请选择开始日期',
    },
    timeValue: {
      type: String,
      default: 'YYYY-MM-DD', // YYYY-MM-DD or  YYYY-MM-DD-HH:mm
    },
    endPlaceholder: {
      type: String,
      default: '请选择结束日期',
    },
    defaultValue: [String, Object],
  },
  setup(props, context) {
    const pikerValue = ref([] || props.defaultValue);
    function timeChange(val) {
      let timeValue = '';
      if (props.timeType === 'daterange') {
        timeValue = {
          startime: pikerValue.value ? parseTime(val[0], '{y}-{m}-{d}') || '' : '',
          endtime: pikerValue.value ? parseTime(val[1], '{y}-{m}-{d}') || '' : '',
        };
      } else {
        timeValue = parseTime(val, '{y}-{m}-{d}');
      }
      context.emit('timeChange', timeValue);
    }
    return {
      pikerValue,
      timeChange,
    };
  },
};
</script>

<style scoped></style>
