import { createStore } from 'vuex';
import Cookies from 'js-cookie';
export default createStore({
  state: {
    isToken: true, // 全局判断是否登录
    storeDomain: '',
    token: Cookies.get('token'),
    storeId: '',
  },
  mutations: {
    setStoreDomain(state, domain) {
      state.storeDomain = domain;
    },
    setToken(state, token) {
      state.token = token;
    },
    setStoreId(state, storeId) {
      state.storeId = storeId;
    },
  },
});
