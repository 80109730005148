export default {
  path: '/:storeId/deliverytracking/',
  component: () => import('@/app/deliverytracking/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/deliverytracking/views/Home.vue'),
    },
    {
      path: 'statistics',
      component: () => import('@/app/deliverytracking/views/Statistics.vue'),
    },
    {
      path: 'waybill',
      component: () => import('@/app/deliverytracking/views/Waybill.vue'),
    },
    {
      path: 'carrier',
      component: () => import('@/app/deliverytracking/views/Carrier.vue'),
    },
  ],
};
