export default {
  path: '/:storeId/pricecutsremind/',
  component: () => import('@/app/pricecutsremind/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/pricecutsremind/views/Home.vue'),
    },
    {
      path: 'data-stat',
      component: () => import('@/app/pricecutsremind/views/DataStat.vue'),
    },
    {
      path: 'info-shopping-list',
      component: () => import('@/app/pricecutsremind/views/InfoShoppingList.vue'),
    },
    {
      path: 'mail-setting',
      component: () => import('@/app/pricecutsremind/views/MailSetting.vue'),
    },
    {
      path: 'pricecutsremind-setting',
      component: () => import('@/app/pricecutsremind/views/PricecutsremindSetting.vue'),
    },
  ],
};
