export default {
  path: '/:storeId/detailmarkettool/',
  component: () => import('../Index.vue'),
  children: [
    {
      path: '',
      component: () => import('../views/Home.vue'),
    },
    {
      path: 'list',
      component: () => import('../views/List.vue'),
    },
    {
      path: 'list-config/:id/:title',
      component: () => import('../views/ListConfig.vue'),
    },
  ],
};
