import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { api } from './config';
import store from '../store';
import { ElMessage } from 'element-plus';
import { Ref } from '@vue/reactivity';
import { Token } from './variables';

export interface ExpAxiosRequestConfig extends AxiosRequestConfig {
  loading?: Ref<any>;
  reqTime?: number;
  loadingTime?: number;
}

/**
 *
 * @param {*} config 参考 axios 文档
 * @param {Number} timer 延迟时间（ms)，只有请求的事件超过该值时才会返回数据
 */
const request = (config: ExpAxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    axios(config)
      .then(response => {
        const res = response.data;
        resolve(res);
        // 显示异常
        if (res.status !== 1) {
          if (res.msg) {
            ElMessage.error(res.msg);
          }
          return Promise.reject(new Error(res.message || 'Error'));
        } else {
          return res;
        }
      })
      .catch(e => {
        ElMessage.error(e.msg);
        reject(e);
      });
  });
};

export const setRequestApi = () => {
  axios.defaults.baseURL = api();
};

export function setInterceptors() {
  let curLoading: { value: boolean };
  // 请求拦截器
  axios.interceptors.request.use(
    function (conf: ExpAxiosRequestConfig) {
      conf.headers.common['token'] = Cookies.get(Token.Token) || Cookies.get(Token.ApiToken);

      // 实现页面的加载效果
      if (conf.loading) {
        conf.reqTime = new Date().getTime(); // 存储一个发起请求的时间戳

        conf.loading.value = true; // 将传入的 响应式数据loading 设为true
        curLoading = conf.loading; // 将响应式的 loading 放入上一级作用域，方便后续使用
      }

      return conf;
    },
    error => {
      // do something with request error
      console.log(error); // for debug
      return Promise.reject(error);
    },
  );
  // 响应拦截器
  axios.interceptors.response.use(
    res => {
      const {
        loading,
        loadingTime = 300, // 页面loading的最小时间（ms）
        reqTime,
      } = res.config as ExpAxiosRequestConfig;

      // 结束页面的加载效果
      if (loading && reqTime) {
        const curTime = new Date().getTime(); // 获取当前时间戳
        // debugger;
        // 用 loadingTime 减去 响应时间(curTime - reqTime) 可以计算出 页面还需要loading的时间
        const curLoadingTime = loadingTime - (curTime - reqTime);
        // 如果 curLoadingTime 为负数，则说明超出了 loadingTime，直接将 响应式数据loading 设为false，结束页面的加载效果
        processTimeout(curLoadingTime, () => {
          loading.value = false;
        });

        return new Promise(resolve => {
          processTimeout(curLoadingTime, () => {
            resolve(res);
          });
        });
      }

      function processTimeout(
        curLoadingTime: number | undefined,
        callback: { (): void; (): void; (): void },
      ) {
        if (typeof curLoadingTime !== 'undefined' && curLoadingTime > 0) {
          setTimeout(() => {
            callback();
          }, curLoadingTime);
        } else {
          callback();
        }
      }

      return res;
    },
    err => {
      // 如果状态码为 401，则说明用户未登录
      if (err.response.status === 401) {
        store.state.isToken = false;
      }

      curLoading &&
        setTimeout(() => {
          curLoading.value = false;
        }, 500);
      return Promise.reject(err);
    },
  );
}

export default request;
