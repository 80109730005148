export default {
  path: '/:storeId/fbadsboard/',
  component: () => import('@/app/fbadsboard/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/fbadsboard/views/Home.vue'),
    },
    {
      path: 'panel',
      component: () => import('@/app/fbadsboard/views/Board.vue'),
    },
  ],
};
