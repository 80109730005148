export default {
  path: '/:storeId/loqateauto/',
  component: () => import('@/app/loqateauto/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/loqateauto/views/Home.vue'),
    },
    {
      path: 'statistics',
      component: () => import('@/app/loqateauto/views/statistics/Index.vue'),
    },
    {
      path: 'statistics/consume',
      component: () => import('@/app/loqateauto/views/statistics/Consume.vue'),
    },
    {
      path: 'statistics/recharge',
      component: () => import('@/app/loqateauto/views/statistics/Recharge.vue'),
    },
  ],
};
