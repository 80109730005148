
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { getRoute } from '../../tools/config';

type Type = 'text' | 'link';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<Type>,
      default: 'text',
      validator: (val: string) => {
        return ['text', 'link'].includes(val);
      },
    },
    router: {
      type: String as PropType<string>,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      router.back();
    };

    return {
      getRoute,
      goBack,
    };
  },
});
