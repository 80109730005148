import { createApp } from 'vue';
import ElementPlus, { locale } from 'element-plus';
import { setInterceptors } from './tools/request';
import router from './router';
import store from './store';
import App from './App.vue';
import lang from 'element-plus/lib/locale/lang/zh-cn';
import { registComponent } from './components'; //全局注册组件

import '@/assets/scss/element-reset.scss';
import '@/assets/scss/index.scss';

const app = createApp(App);

locale(lang); // 使用语言包
setInterceptors(); // 执行 axios 拦截器
registComponent(app); // 注册全局组件

app.use(router).use(store).use(ElementPlus).mount('#app');
