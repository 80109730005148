/**
 * 获取url参数
 * @param {String} variable 参数名
 * @returns 对应的url参数
 */

export function getUrlParams(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return '';
}

/**
 *
 * @param {String} paramKey 参数名
 * @returns 被处理过的url
 */
export const delParam = (paramKey: string) => {
  let url = window.location.href; //页面url
  const urlParam = window.location.search.substr(1); //页面参数
  const beforeUrl = url.substr(0, url.indexOf('?')); //页面主地址（参数之前地址）
  let nextUrl = '';

  const arr: string[] = [];
  if (urlParam != '') {
    const urlParamArr = urlParam.split('&'); //将参数按照&符分成数组
    for (let i = 0; i < urlParamArr.length; i++) {
      const paramArr = urlParamArr[i].split('='); //将参数键，值拆开
      //如果键雨要删除的不一致，则加入到参数中
      if (paramArr[0] != paramKey) {
        arr.push(urlParamArr[i]);
      }
    }
  }
  if (arr.length > 0) {
    nextUrl = '?' + arr.join('&');
  }
  url = beforeUrl + nextUrl;
  return url;
};

/**
 * 修改url参数
 * @param {String} name 参数名
 * @param {String} value 参数值
 */
export const changeURLParams = (name: string, value: string) => {
  const url = location.href;
  const reg = eval('/([?|&]' + name + '=)[^&]*/gi');
  value = value.toString().replace(/(^\s*)|(\s*$)/g, ''); //移除首尾空格
  let url2;
  if (!value) {
    url2 = url.replace(reg, ''); //正则替换
  } else {
    if (url.match(reg)) {
      url2 = url.replace(reg, '$1' + value); //正则替换
    } else {
      url2 = url + (url.indexOf('?') > -1 ? '&' : '?') + name + '=' + value; //没有参数添加参数
    }
  }
  history.replaceState(null, '', url2); //替换地址栏
};
/**
 * Parse the time to string
 * @param {(Date|string|number)} time 时间
 * @param {string} cFormat 时间格式（例如：{y}-{m}-{d} {h}:{i}:{s}）
 * @returns {string}
 */
export function parseTime(time: string | number | Date, cFormat: string) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };

  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    // @ts-ignore
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}
// 防抖
export function _debounce(fn: { apply: (arg0: any, arg1: IArguments) => void }, delay: number) {
  const fddelay = delay || 200;
  let timer: NodeJS.Timeout | null;
  return function () {
    // @ts-ignore
    const th = this;
    // eslint-disable-next-line
    const args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, fddelay);
  };
}
// 节流
export function _throttle(fn: { apply: (arg0: any, arg1: IArguments) => void }, interval: number) {
  let last: number;
  let timer: NodeJS.Timeout;
  const jlinterval = interval || 200;
  return function () {
    // @ts-ignore
    const th = this;
    // eslint-disable-next-line
    const args = arguments;
    const now = +new Date();
    if (last && now - last < jlinterval) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.apply(th, args);
      }, jlinterval);
    } else {
      last = now;
      fn.apply(th, args);
    }
  };
}

/**
 * 判断是否为纯对象
 * @param {*} tar
 * @returns 是否为 纯对象
 */
export const isPlainObjct = (tar: any) => {
  return Object.prototype.toString.call(tar) === '[object Object]';
};

export const isUrl = (str: string): boolean => {
  const match =
    /^((http|https):\/\/)?(([A-Za-z0-9]+-[A-Za-z0-9]+|[A-Za-z0-9]+)\.)+([A-Za-z]+)[/?:]?.*$/;

  return match.test(str);
};

export const copy = (text: string) => {
  // debugger
  const input = document.createElement('input');
  input.value = text;

  document.body.appendChild(input);
  input.select();

  if (document.execCommand) {
    document.execCommand('copy');
    document.body.removeChild(input);
    return true;
  } else {
    document.body.removeChild(input);
    return false;
  }
};
