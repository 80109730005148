export default {
  path: '/:storeId/giftcard/',
  component: () => import('@/app/giftcard/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/giftcard/views/Home.vue'),
    },
    {
      path: 'gift-list',
      component: () => import('@/app/giftcard/views/GiftList.vue'),
    },
    {
      path: 'gift-list-edit/:giftId?',
      component: () => import('@/app/giftcard/views/GiftListEdit.vue'),
    },
    {
      path: 'mail-setting',
      component: () => import('@/app/giftcard/views/MailSetting.vue'),
    },
    {
      path: 'gift-detail',
      component: () => import('@/app/giftcard/views/GiftDetail.vue'),
    },
  ],
};
