export default {
  path: '/:storeId/imagesearch/',
  component: () => import('@/app/imagesearch/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/imagesearch/views/Home.vue'),
    },
    {
      path: 'basic-setting',
      component: () => import('@/app/imagesearch/views/BasicSetting.vue'),
    },
    {
      path: 'data-analysis',
      component: () => import('@/app/imagesearch/views/DataAnalysis.vue'),
    },
    {
      path: 'goods-list',
      component: () => import('@/app/imagesearch/views/GoodsList.vue'),
    },
    {
      path: 'search-history',
      component: () => import('@/app/imagesearch/views/SearchHistory.vue'),
    },
  ],
};
